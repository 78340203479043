@import '~@alaskaairux/design-tokens/dist/tokens/SCSSVariables';
@import "~@alaskaairux/webcorestylesheets/dist/breakpoints";
@import '~@alaskaairux/webcorestylesheets/dist/fonts';
@import "~@alaskaairux/webcorestylesheets/dist/normalize";
@import "~@alaskaairux/webcorestylesheets/dist/essentials";
@import "~@alaskaairux/webcorestylesheets/dist/utilityClasses";
@import '~@alaskaairux/design-tokens/dist/tokens/CSSCustomProperties';
@import '~@alaskaairux/design-tokens/dist/tokens/SassCustomProperties'; // Auro design tokens as CSS Custom Properties, used by auro-* components

@import "~@alaskaairux/webcorestylesheets/dist/core";
@import '~@alaskaairux/webcorestylesheets/dist/headings';

a{
    color: var(--auro-color-brand-breeze-300);
}